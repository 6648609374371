//! moment.js locale configuration
//! locale : Pseudo [x-pseudo]
//! author : Andrew Hood : https://github.com/andrewhood125

import moment from '../moment';

export default moment.defineLocale('x-pseudo', {
    months: 'J~áñúá~rý_F~ébrú~árý_~Márc~h_Áp~ríl_~Máý_~Júñé~_Júl~ý_Áú~gúst~_Sép~témb~ér_Ó~ctób~ér_Ñ~óvém~bér_~Décé~mbér'.split(
        '_'
    ),
    monthsShort:
        'J~áñ_~Féb_~Már_~Ápr_~Máý_~Júñ_~Júl_~Áúg_~Sép_~Óct_~Ñóv_~Déc'.split(
            '_'
        ),
    monthsParseExact: true,
    weekdays:
        'S~úñdá~ý_Mó~ñdáý~_Túé~sdáý~_Wéd~ñésd~áý_T~húrs~dáý_~Fríd~áý_S~átúr~dáý'.split(
            '_'
        ),
    weekdaysShort: 'S~úñ_~Móñ_~Túé_~Wéd_~Thú_~Frí_~Sát'.split('_'),
    weekdaysMin: 'S~ú_Mó~_Tú_~Wé_T~h_Fr~_Sá'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'HH:mm',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd, D MMMM YYYY HH:mm',
    },
    calendar: {
        sameDay: '[T~ódá~ý át] LT',
        nextDay: '[T~ómó~rró~w át] LT',
        nextWeek: 'dddd [át] LT',
        lastDay: '[Ý~ést~érdá~ý át] LT',
        lastWeek: '[L~ást] dddd [át] LT',
        sameElse: 'L',
    },
    relativeTime: {
        future: 'í~ñ %s',
        past: '%s á~gó',
        s: 'á ~féw ~sécó~ñds',
        ss: '%d s~écóñ~ds',
        m: 'á ~míñ~úté',
        mm: '%d m~íñú~tés',
        h: 'á~ñ hó~úr',
        hh: '%d h~óúrs',
        d: 'á ~dáý',
        dd: '%d d~áýs',
        M: 'á ~móñ~th',
        MM: '%d m~óñt~hs',
        y: 'á ~ýéár',
        yy: '%d ý~éárs',
    },
    dayOfMonthOrdinalParse: /\d{1,2}(th|st|nd|rd)/,
    ordinal: function (number) {
        var b = number % 10,
            output =
                ~~((number % 100) / 10) === 1
                    ? 'th'
                    : b === 1
                      ? 'st'
                      : b === 2
                        ? 'nd'
                        : b === 3
                          ? 'rd'
                          : 'th';
        return number + output;
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
});
