//! moment.js locale configuration
//! locale : Catalan [ca]
//! author : Juan G. Hurtado : https://github.com/juanghurtado

import moment from '../moment';

export default moment.defineLocale('ca', {
    months: {
        standalone:
            'gener_febrer_març_abril_maig_juny_juliol_agost_setembre_octubre_novembre_desembre'.split(
                '_'
            ),
        format: "de gener_de febrer_de març_d'abril_de maig_de juny_de juliol_d'agost_de setembre_d'octubre_de novembre_de desembre".split(
            '_'
        ),
        isFormat: /D[oD]?(\s)+MMMM/,
    },
    monthsShort:
        'gen._febr._març_abr._maig_juny_jul._ag._set._oct._nov._des.'.split(
            '_'
        ),
    monthsParseExact: true,
    weekdays:
        'diumenge_dilluns_dimarts_dimecres_dijous_divendres_dissabte'.split(
            '_'
        ),
    weekdaysShort: 'dg._dl._dt._dc._dj._dv._ds.'.split('_'),
    weekdaysMin: 'dg_dl_dt_dc_dj_dv_ds'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'H:mm',
        LTS: 'H:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM [de] YYYY',
        ll: 'D MMM YYYY',
        LLL: 'D MMMM [de] YYYY [a les] H:mm',
        lll: 'D MMM YYYY, H:mm',
        LLLL: 'dddd D MMMM [de] YYYY [a les] H:mm',
        llll: 'ddd D MMM YYYY, H:mm',
    },
    calendar: {
        sameDay: function () {
            return '[avui a ' + (this.hours() !== 1 ? 'les' : 'la') + '] LT';
        },
        nextDay: function () {
            return '[demà a ' + (this.hours() !== 1 ? 'les' : 'la') + '] LT';
        },
        nextWeek: function () {
            return 'dddd [a ' + (this.hours() !== 1 ? 'les' : 'la') + '] LT';
        },
        lastDay: function () {
            return '[ahir a ' + (this.hours() !== 1 ? 'les' : 'la') + '] LT';
        },
        lastWeek: function () {
            return (
                '[el] dddd [passat a ' +
                (this.hours() !== 1 ? 'les' : 'la') +
                '] LT'
            );
        },
        sameElse: 'L',
    },
    relativeTime: {
        future: "d'aquí %s",
        past: 'fa %s',
        s: 'uns segons',
        ss: '%d segons',
        m: 'un minut',
        mm: '%d minuts',
        h: 'una hora',
        hh: '%d hores',
        d: 'un dia',
        dd: '%d dies',
        M: 'un mes',
        MM: '%d mesos',
        y: 'un any',
        yy: '%d anys',
    },
    dayOfMonthOrdinalParse: /\d{1,2}(r|n|t|è|a)/,
    ordinal: function (number, period) {
        var output =
            number === 1
                ? 'r'
                : number === 2
                  ? 'n'
                  : number === 3
                    ? 'r'
                    : number === 4
                      ? 't'
                      : 'è';
        if (period === 'w' || period === 'W') {
            output = 'a';
        }
        return number + output;
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
});
